<template>
  <div class="ImageUploadForm">
    <SectionHeadline
      v-if="title"
      :text="title"
      :extraText="getRequiredText()"/>
    <div class="ImageList">
      <!-- Uploaded images -->
      <div
        v-for="(image, index) in images"
        :key="image.base.path"
        class="ImageWrap">
        <MultiImage
          :image="image"
          :transformation="transformation"
          preview
          enableDelete
          :onCustomConfirmDelete="onCustomConfirmDelete"
          @delete="onImageDelete(index)" />
      </div>

      <template
        v-if="maxNumber === false || images.length < maxNumber">
        <!-- Take image -->
        <label
          :for="newImageId"
          class="UploadButton">
          <input
            :id="newImageId"
            :name="newImageId"
            type="file"
            accept="image/*"
            capture="true"
            @change="onImagesSelected" />
          <div class="TopPadding"/>
          <div class="IconWrap">
            <CameraIcon />
          </div>
        </label>

        <!-- Upload images from storage -->
        <label
          :for="uploadImagesId"
          class="UploadButton">
          <input
            :id="uploadImagesId"
            :name="uploadImagesId"
            type="file"
            accept="image/*"
            multiple
            @change="onImagesSelected" />
          <div class="TopPadding"/>
          <div class="IconWrap">
            <PhotosIcon />
          </div>
        </label>
      </template>
      <slot />
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import CameraIcon from '@/assets/svg/camera.svg?inline'
import PhotosIcon from '@/assets/svg/photos.svg?inline'
import MultiImage from '@/components/Images/MultiImage.vue'
import { minImageSize, prepareImage } from '@/globals/javascript/_util/images'
import EventBus from '@/EventBus'

export default {
  name: 'ImageUploadForm',
  props: {
    place: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    requiredNumber: {
      type: Number,
      required: false,
      default: 0,
    },
    maxNumber: {
      type: [Number, Boolean],
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    basePath: {
      type: String,
      required: true,
    },
    minSize: {
      type: Number,
      required: false,
      default: minImageSize,
    },
    extraSizes: {
      type: Array,
      required: false,
      default: () => [],
    },
    makeSquared: {
      type: Boolean,
      required: false,
      default: false,
    },
    makePortrait: {
      type: Boolean,
      required: false,
      default: false,
    },
    onCustomConfirmDelete: {
      type: Function,
      required: false,
    },
    resetImagesCounter: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      minImageSize,
      prepareImage,
      images: [],
      imageIndexCounter: 0,
      defaultMaxNumber: 10,
    }
  },
  computed: {
    newImageId() {
      return `new-image-${ this.groupName }`
    },
    uploadImagesId() {
      return `upload-images-${ this.groupName }`
    },
    allowedImages() {
      return this.maxNumber || this.defaultMaxNumber
    },
    transformation() {
      return this.makePortrait ? 'resize_min_240' : 'square_200'
    },
  },
  watch: {
    resetImagesCounter() {
      this.onResetImages()
    },
  },
  methods: {
    getRequiredText() {
      if (!this.requiredNumber) {
        return ''
      }

      // Text for a specific number of images
      if (this.requiredNumber === this.allowedImages) {
        if (this.requiredNumber === 1) {
          return this.mixWB('EXPECTS_1_IMAGE')
        }

        return this.mixWB('EXPECTS_X_IMAGES', [this.requiredNumber])
      }

      // Text for a minimum of images
      if (this.requiredNumber === 1) {
        return this.mixWB('EXPECTS_AT_LEAST_1_IMAGE')
      }

      return this.mixWB('EXPECTS_AT_LEAST_X_IMAGES', [this.requiredNumber])
    },
    onImagesSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      let remainingAllowedImages = this.allowedImages - this.images.length

      remainingAllowedImages = remainingAllowedImages < 0 ? 0 : remainingAllowedImages

      files.forEach((file, index) => {
        if (index < remainingAllowedImages) {
          this.onNewFile(file)
        }
      })
    },
    onNewFile(file) {
      this.imageIndexCounter += 1

      // Create image object
      const imageObject = this.prepareImage({
        basePath: this.basePath,
        minSize: this.minSize,
        file,
        postFix: this.imageIndexCounter,
        extraSizes: this.extraSizes,
      })

      // Show image
      this.images.push(imageObject)

      // Save image sizes for upload
      this.$store.dispatch('uploadImage', {
        imageObject,
        file,
        place: this.place,
        makeSquared: this.makeSquared,
        makePortrait: this.makePortrait,
      })

      // Emit image
      this.$emit('image-list-update', { imageList: this.images, groupName: this.groupName })
    },
    onImageDelete(index) {
      this.images.splice(index, 1)
      this.$emit('image-list-update', { imageList: this.images, groupName: this.groupName })
    },
    onResetImages() {
      this.images = []
    },
    setImagesOnLoad({ groupName, imageList }) {
      if (groupName !== this.groupName) {
        return
      }

      this.imageIndexCounter = imageList.length

      this.images = imageList
    },

  },
  components: {
    SectionHeadline,
    CameraIcon,
    PhotosIcon,
    MultiImage,
  },
  created() {
    EventBus.$on('set-images-on-load', this.setImagesOnLoad)
  },
  destroyed() {
    EventBus.$off('set-images-on-load', this.setImagesOnLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .ImageUploadForm
    display block

  .ImageList
    display flex
    flex-wrap wrap
    margin-left -5px
    margin-right -5px

  .ImageWrap
    position relative
    width calc(20% - 5px)
    max-width 200px
    margin 0px 2.5px 5px
    box-shadow $box_shadow_1
    border 1px solid lighten($color_grey_light, 25%)
    cursor pointer

  .UploadButton
    display block
    position relative
    width calc(20% - 5px)
    max-width 200px
    margin 0px 2.5px 5px
    background-color $color_primary
    box-shadow $box_shadow_1
    border 1px solid lighten($color_primary, 15%)
    cursor pointer
    input
      box(0px)
      overflow hidden
    .IconWrap
      box(100%)
      padding 20%
      position absolute
      top 0
      left 0
      svg
        fill #fff

  .TopPadding
    width 100%
    padding-top 100%
</style>
