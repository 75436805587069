<template>
  <div
    v-if="imageUploadFolder"
    class="ScreeningsUpdateProfileImages">
    <div class="InnerPage">
      <div class="TitleWrap">
        <span class="PageTitle">{{ mixWB('PROFILE_IMAGES') }}</span>
        <SingleUnitSelectedText />
      </div>

      <CloseButton />

      <!-- Address images -->
      <div
        v-if="!currentScreeningSelectedFilterUnitID"
        class="ImageUploadWrap">
        <ImageUploadForm
          :basePath="getAddressBasePath()"
          :minSize="1200"
          :place="mixWB('PROFILE_IMAGE_OF_THE_ADDRESS')"
          :requiredNumber="1"
          :maxNumber="1"
          :title="mixWB('COVER_IMAGE')"
          makeSquared
          groupName="overview"
          @image-list-update="onImageListUpdate" />
      </div>

      <!-- Building images -->
      <div
        class="ImageUploadWrap"
        v-for="unit in buildingsToShow"
        :key="unit.id">
        <ImageUploadForm
          :place="unit.getFullTitle()"
          :requiredNumber="1"
          :maxNumber="10"
          :title="unit.getFullTitle()"
          makeSquared
          :basePath="getUnitBasePath(unit.id)"
          :groupName="unit.id"
          @image-list-update="onImageListUpdate" />
      </div>

      <NextStepLine
        :isDisabled="!profileImageStatus.isCompleted"
        :showNeedCompleteText="!profileImageStatus.isCompleted"
        showTopBorder />

      <div
        class="CadastralMapImageWrap"
        v-if="cadastralMapImage">
        <span class="Title">{{ mixWB('CADASTRAL_MAP') }}</span>
        <MultiImage
          :image="cadastralMapImage"
          transformation="square_400" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import ImageUploadForm from '@/components/Forms/ImageUploadForm.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import MultiImage from '@/components/Images/MultiImage.vue'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'
import NextStepLine from '@/components/ScreeningItems/NextStepLine.vue'

export default {
  name: 'ScreeningsUpdateProfileImages',
  computed: {
    ...mapGetters([
      'addressImages',
      'profileImageStatus',
      'imageUploadFolder',
      'currentScreeningSelectedFilterUnitID',
      'combinedListOfUnits',
      'buildingsInUse',
      'isDemoUser',
    ]),
    buildingsToShow() {
      if (!this.currentScreeningSelectedFilterUnitID) {
        return this.buildingsInUse
      }

      const currentUnit = this.combinedListOfUnits.find(
        (x) => x.id === this.currentScreeningSelectedFilterUnitID,
      )
      const currentBuildingID = currentUnit.getBuildingID()

      return this.buildingsInUse.filter((x) => x.id === currentBuildingID)
    },
    cadastralMapImage() {
      if (!this.isDemoUser && this.addressImages?.overview?.cadastralMap?.length) {
        return this.addressImages.overview.cadastralMap[
          this.addressImages.overview.cadastralMap.length - 1
        ]
      }

      return false
    },
  },
  methods: {
    getAddressBasePath() {
      return `${ this.imageUploadFolder }AddressImages/Overview/`
    },
    getUnitBasePath(unitID) {
      return `${ this.imageUploadFolder }AddressImages/Units/${ unitID }/`
    },
    setImagesOnLoad() {
      const waitForData = setInterval(() => {
        if (this.addressImages === null) {
          return
        }

        if (!this.buildingsInUse.length) {
          return
        }

        clearInterval(waitForData)

        // Load address profile image
        if (this.addressImages?.overview?.profile) {
          EventBus.$emit('set-images-on-load', {
            imageList: this.addressImages.overview.profile,
            groupName: 'overview',
          })
        }

        // Load building images
        this.buildingsToShow.forEach((building) => {
          const unitImages = this?.addressImages?.units?.[building.id]

          if (unitImages && unitImages.profile) {
            EventBus.$emit('set-images-on-load', {
              imageList: unitImages.profile,
              groupName: building.id,
            })
          }
        })
      }, 100)
    },
    onImageListUpdate({ imageList, groupName: place }) {
      this.$store.dispatch('setAddressImages', {
        imageList,
        place,
        imageType: 'profile',
      })
    },
  },
  components: {
    CloseButton,
    ImageUploadForm,
    MultiImage,
    SingleUnitSelectedText,
    NextStepLine,
  },
  created() {
    this.setImagesOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateProfileImages
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .TitleWrap
    margin-bottom 20px
    .PageTitle
      display block
      font-size 1.125rem
      font-weight bold
      text-transform uppercase

  .ImageUploadWrap
    margin-bottom 20px
    >>> .ImageList
      align-items flex-start

  .CadastralMapImageWrap
    margin-top 20px
    .Title
      display block
      font-size 0.875rem
      padding-bottom 2px
</style>
